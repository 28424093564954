<template>
	<div id="main-app">
		<!--Waves Container-->
		<div>
			<svg
				class="waves"
				xmlns="http://www.w3.org/2000/svg"
				xmlns:xlink="http://www.w3.org/1999/xlink"
				viewBox="0 24 150 28"
				preserveAspectRatio="none"
				shape-rendering="auto"
			>
				<defs>
					<path
						id="gentle-wave"
						d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
					/>
					<path
						id="gentle-wave-2"
						d="M -180 44 c 30 0 58 -18 88 -18 s 58 18 88 18 s 58 -18 88 -18 s 58 18 88 18 v 74 h -352 z"
					/>
				</defs>
				<g class="parallax">
					<use
						xlink:href="#gentle-wave"
						x="48"
						y="0"
						fill="rgba(22,49,250,0.4"
					/>
					<use
						xlink:href="#gentle-wave"
						x="48"
						y="3"
						fill="rgba(22,49,250,0.5)"
					/>
					<use
						xlink:href="#gentle-wave-2"
						x="48"
						y="5"
						fill="rgba(22,49,250,0.3)"
					/>
					<use
						xlink:href="#gentle-wave-2"
						x="48"
						y="7"
						fill="rgba(22,49,250,0.6)"
					/>
				</g>
			</svg>
		</div>
		<!--Waves end-->
		<slot></slot>
	</div>
</template>

<script setup>
// import {
// 	MDBContainer,
// 	MDBRow,
// 	MDBCol,
// 	MDBCard,
// 	MDBCardTitle,
// 	MDBCardText,
// } from "mdb-vue-ui-kit"
// import AuthenticationCardLogo from "@/Components/AuthenticationCardLogo.vue"
// import { onMounted, ref } from "vue"

import { usePreferenceStore } from "@/Store/adminPreferenceStore"
const preferenceStore = usePreferenceStore()
// const adminTheme = preferenceStore.getPreference
// const initVars = JSON.parse(atob(window.efboot))
</script>

<style scoped>
@media (min-width: 992px) {
	:deep(.form) {
		max-width: 600px;
		height: 100%;
	}
	:deep(.card) {
		height: 100%;
		border-radius: 0;
	}
}
#main-app {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background-color: rgb(12, 12, 50);
}
:deep(.platform-login-logo) {
	width: auto;
	max-height: 300px;
	max-width: 100%;
}
:deep(.password-popover) {
	position: absolute;
	right: 14px;
	left: auto;
	top: 50%;
	transform: translateY(-50%);
}
/* @media (min-width: 991px){
	:deep(.bg-glass) {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		margin-right: 0 !important;
		border-radius: 0;
		background-color: rgba(255, 255, 255, 0.6) !important;
	}
} */

.waves {
	position: fixed;
	width: auto;
	height: "70%";
	bottom: 0;
	margin-bottom: -7px; /*Fix for safari gap*/
	min-height: 300px;
	max-height: "70%";
}
/* Animation */

.parallax > use {
	animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
	animation-delay: -10s;
	animation-duration: 10s;
}
.parallax > use:nth-child(2) {
	animation-delay: -14s;
	animation-duration: 20s;
}
.parallax > use:nth-child(3) {
	animation-delay: -18s;
	animation-duration: 40s;
}
.parallax > use:nth-child(4) {
	animation-delay: -4s;
	animation-duration: 30s;
}
@keyframes move-forever {
	0% {
		transform: translate3d(-90px, 0, 0);
	}
	100% {
		transform: translate3d(85px, 0, 0);
	}
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
	/* .waves {
		height: 40px;
		min-height: 40px;
	} */
}
</style>
